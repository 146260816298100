<template>
  <div>
    <!-- <h1>Booking</h1> -->
    <div class="booking">
      <div>
        <h1>Booking</h1>
        <h2>Call <a href="tel:855-662-2759">855-662-2759</a> to book your flight today!</h2>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Booking',
  components: {
  }
}
</script>

<style lang="scss" scoped>
.booking {
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
  padding: 25px;
  h2 {
    font-size: 24px;
  }
  a {
    color: unset;
  }
}
</style>
